<template>
  <ul class="py-4 space-y-2 text-orange-500 lg:py-6">
    <li>
      <div class="cursor-pointer hover:no-underline" @click="scrollToTop">
        Einstieg
      </div>
    </li>
    <li v-for="(header, index) in headers" :key="index">
      <div
        class="cursor-pointer hover:no-underline"
        @click="scrollToElement(header)"
      >
        {{ header.innerText }}
      </div>
    </li>
  </ul>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";

export default {
  name: "SiteContentComponents",
  setup() {
    const headers = ref([]);

    onMounted(() => {
      headers.value = document.querySelectorAll("h2");

      headerOverlapsSiteContent();
    });

    function scrollToElement(element) {
      const offset = element.parentElement.parentElement.offsetTop;
      scroll(offset, offset);
    }

    function scrollToTop() {
      const article = document.querySelector("article");
      scroll(article, article.offsetTop);
    }

    function scroll(target, offset) {
      if (!target) {
        return;
      }

      window.scroll({
        behavior: "smooth",
        left: 0,
        top: offset - 150,
      });
    }

    function headerOverlapsSiteContent() {
      const header = document.querySelector("header");
      const wrapper = document.querySelector(".bu-site-content-wrapper");

      if (!wrapper || !header) {
        return;
      }

      const wrapperCoords = wrapper.getBoundingClientRect();
      const headerOffset = header.offsetHeight;

      changeSiteContentPosition(wrapper, wrapperCoords.y, headerOffset);

      document.addEventListener("scroll", () => {
        changeSiteContentPosition(wrapper, wrapperCoords.y, headerOffset);
      });
    }

    function changeSiteContentPosition(wrapper, wrapperY, headerOffset) {
      let offset = window.pageYOffset + headerOffset;

      if (offset >= wrapperY) {
        wrapper.style.paddingTop = `${headerOffset + 10}px`;
      } else {
        wrapper.style.paddingTop = "0";
      }
    }

    return {
      headers,
      scrollToElement,
      scrollToTop,
    };
  },
};
</script>
