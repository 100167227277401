import Vue from "@/helper/initVue";

import SiteContentComponent from "@/components/SiteContentComponent.vue";

const wrapperElements = document.querySelectorAll(".bu-site-content");

Array.from(wrapperElements).forEach((wrapper) => {
  if (wrapper) {
    new Vue({
      el: wrapper,
      render: (h) => h(SiteContentComponent),
    });
  }
});
